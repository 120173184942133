import { React, useEffect } from "react";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import { useState } from "react";
import { UseEffectOnce } from "../Hook/UseEffectOnce";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../layout";
import { Col, Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import doneImg from "../assets/images/doneImg.png";
import Auth from "../libs/auth";
const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

function CustomizeCredits() {
  const user = Auth.getCurrentUser()
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const nowTime = new Date();
  const date = nowTime.getTime();
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const presets = [0, 10, 20, 30, 40, 50, 60, 70];
  const [creditPrice, setCreditPrice] = useState({
    items: [],
    has_more: false,
  });
  const [value, setValue] = useState(0);

  UseEffectOnce(() => {
    getPatientCreditPrice();
  }, []);
  // Handler for the range input that updates the state
  const handleSliderChange = (event) => {
    setValue(event.target.value);
  };

  // Handler for clicking on preset values
  const handlePresetClick = (presetValue) => {
    setValue(presetValue);
  };

  async function getPatientCreditPrice() {
    try {
      setIsLoading(true);
      const data = await get("/PatientCreditPriceList", getAuthConfig());
      if (data?.status === 200) {
        setCreditPrice((p) => ({ ...p, items: data?.data?.msg }));
      }
    } catch (error) {
    } finally {
      setIsLoading(false); // Set isLoading to false in the finally block
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setLoading(false);
  };

  async function displayRazorpay() {
    setLoading(true);  // Start loading before the script loads
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      setLoading(false);
      return;
    }
    const nowTime = new Date();
    const date = nowTime.getTime();
    
    const userData = {
      baseAmount:value * Number(creditPrice?.items[0]?.PatientCreditAmount) * 100
    };
    try {
    const data = await post('/api/create-order',userData ,getAuthConfig());
    const result =  data.data
  
    if (!result.order) {
      alert('Failed to create subscription. Please try again.');
      return;
    }
  
    const options = {
      key: 'rzp_live_opK2gM7NYmZGRs', // Enter the Key ID generated from the Razorpay dashboard
      order_id: result.order.id,
      amount: result.order.amount,
      currency: result.order.currency,
      name: "Lauruss",
      description: "Subscription for Premium Plan",
      image: "https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/VirtueLifelogo.svg",
      handler: async function (response) {
        const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
        // Call your backend to verify the payment and activate the subscription
//setSubscribeId(razorpay_payment_id)
        const verifyUrl = '/api/verify-payment';
        const verifyData = { orderId: razorpay_order_id, paymentId: razorpay_payment_id, signature: razorpay_signature ,patientCountToAdd:value };
        const verifyResponse = await post(verifyUrl,verifyData ,getAuthConfig());
        const verifyResult = verifyResponse?.data
        if (verifyResult.valid) {
          setCompleteWorkout(true);
          // alert('Subscription successful');
          // Handle further actions like updating UI or database accordingly
        } else {
          alert('Subscription verification failed');
        }
      },
      prefill: {
        name: user?.name,
        email: user?.email,
        contact: user?.mobile_number
      },
      theme: {
        color: "#ff6036"
      }
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }catch (error) {
      console.error('Error creating subscription:', error);
      alert('Failed to process your request. Please try again.');
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Layout>
        {isLoading ? (
          <div className="preloader-whirlpool text-center align-items-center">
            <div className="whirlpool"></div>
          </div>
        ) : (
          <div className="container my-5">
            <div className="recureme-pricing-in" style={{ position: "relative" }}>
              <div className="div">
                <div className="text-wrapper">Customize Credits</div>
                <div className="group p-3">
                  <div className="text-wrapper-2">Patient Credits</div>
                  <p className="p">
                    10 Patient (Current plan) + {value} additional/mo
                  </p>
                  <div className="text-wrapper-3">
                    ₹{" "}
                    {value * Number(creditPrice?.items[0]?.PatientCreditAmount)}
                    /mo
                  </div>
                  <p className="text-wrapper-4">
                    Patient credits are shared across Doctors, Select how many
                    Patient credits you would like for your clinic below.
                  </p>

                  <br />

                  <div
                    className="ml-3 mr-3"
                    style={{
                      position: "absolute",
                      top: "150px",
                      width: "calc(100% - 48px)", // Adjust width considering left and right margins
                      left: "24px",
                    }}
                  >
                    <div>
                      {value} Patient(s) {value === 10 ? "(Current plan)" : ""}{" "}
                      + {value} additional / mo
                    </div>
                    <input
                      type="range"
                      min={0}
                      className="slider"
                      max={presets[presets.length - 1]}
                      value={value}
                      onChange={handleSliderChange}
                      step={1} // Step is set to 1 so you can only select the preset values
                    />
                    <div className="presets">
                      {presets.map((preset) => (
                        <button
                          className={`preset-button ${
                            value === preset ? "active" : ""
                          }`}
                          key={preset}
                          onClick={() => handlePresetClick(preset)}
                        >
                          {preset}/mo
                        </button>
                      ))}
                      {/* <button className="preset-button custom" onClick={() => handlePresetClick('Custom')}>Custom</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>

                          {value > 0 &&
            <div className="container">
                <div className="buttonPart mt-3 d-flex justify-content-center mb-5">
                  <button
                    className="theme-button text-white border-0"
                    onClick={displayRazorpay}
                    disabled={loading}
                  >
                    {loading ? "Paying" : "Pay with RazorPay"}
                  </button>
                </div>
            </div>
}
          </div>
        )}

<Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Header>
            <div className="top-heading w-100 d-flex justify-content-end text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => {setCompleteWorkout(false)
                navigate("/myPlanDetails")
                }}
              >
                X
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="content-block text-center">
              <img src={doneImg} height={300} width={360} alt="modal-video" />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Payment successfully</p>

                <button
                  className="theme-button btn btn-primary mt-3"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/myPlanDetails")
                }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    </>
  );
}
const App = () => {
  return (
      <CustomizeCredits />
  );
};

export default App;
